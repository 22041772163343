import PropTypes from 'prop-types'
import * as React from 'react'
import { ContainerStyled, SectionStyled, HashtagStyled } from 'components/UI'
import FadeUpAnimation from 'components/Animations/FadeUpAnimation'
import * as S from './styled'

function SummaryBlock({
  summaryLabel,
  clientTitle,
  clientDescription,
  deliveryTitle,
  deliveryList,
  hashtagList,
}) {
  return (
    <SectionStyled $paddingBottom={{ xs: 80, md: 90, lg: 100 }} $paddingTop={0}>
      <ContainerStyled>
        <FadeUpAnimation>
          {(refItem) => (
            <S.Headline ref={refItem}>
              <S.Title>{summaryLabel}</S.Title>
              <S.HashtagList>
                {hashtagList.map((item, index) => {
                  return (
                    <HashtagStyled as="li" key={index}>
                      {item.name}
                    </HashtagStyled>
                  )
                })}
              </S.HashtagList>
            </S.Headline>
          )}
        </FadeUpAnimation>
        <S.Summary>
          <FadeUpAnimation>
            {(refItem) => (
              <S.SummaryRequest ref={refItem}>
                <S.Subtitle>{clientTitle}</S.Subtitle>
                <S.SummaryWysiwyg description={clientDescription || ''} />
              </S.SummaryRequest>
            )}
          </FadeUpAnimation>
          <FadeUpAnimation>
            {(refItem) => (
              <S.SummaryDelivery ref={refItem}>
                <S.Subtitle>{deliveryTitle}</S.Subtitle>
                <S.DeliveryList>
                  {deliveryList?.map((item, index) => {
                    return (
                      <S.DeliveryItem key={index}>
                        {item.deliveredItem}
                      </S.DeliveryItem>
                    )
                  })}
                </S.DeliveryList>
              </S.SummaryDelivery>
            )}
          </FadeUpAnimation>
        </S.Summary>
      </ContainerStyled>
    </SectionStyled>
  )
}

SummaryBlock.propTypes = {
  summaryLabel: PropTypes.string,
  clientTitle: PropTypes.string,
  clientDescription: PropTypes.string,
  deliveryTitle: PropTypes.string,
  deliveryList: PropTypes.arrayOf(
    PropTypes.shape({
      deliveredItem: PropTypes.string,
    })
  ),
  hashtagList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
}

SummaryBlock.defaultProps = {
  deliveryList: [],
  hashtagList: [],
  summaryLabel: '',
  clientTitle: '',
  clientDescription: '',
  deliveryTitle: '',
}
export default SummaryBlock
