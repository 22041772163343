import styled from 'styled-components'
import * as mixins from 'Theme/mixins'
import DefaultButton from '../DefaultButton'

export const button = styled(DefaultButton)`
  padding: 0;
  width: ${mixins.pxToRem(60)};
  height: ${mixins.pxToRem(60)};
  border-radius: 50%;
  margin-bottom: ${mixins.pxToRem(10)};
`
