import * as React from 'react'
import { navigate } from 'gatsby'
import { useContext } from 'react'
import { CursorContext } from 'context'
import * as S from './styled'

const BackButton = () => {
  const { onCursor } = useContext(CursorContext)

  return (
    <S.button
      mouseEnter={() => onCursor('blended')}
      mouseLeave={() => onCursor()}
      iconEnd="singleArrowLeft"
      onClick={() =>
        navigate(-1, {
          state: {
            restoreScrollState: true,
          },
        })
      }
    />
  )
}

export default BackButton
