import styled from 'styled-components'
import * as mixins from 'Theme/mixins'
import LinkUnified from 'components/LinkUnified'

export const Card = styled.li`
  position: relative;
  /*cursor: none;*/
  --link-underline: none;
  --link-underline-hover: none;
  margin-bottom: ${mixins.pxToRem(80)};

  ${({ theme }) => theme.mediaBreakpointUp('sm')} {
    margin-bottom: ${mixins.pxToRem(90)};
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp('lg')} {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    backface-visibility: hidden;
    --card-image-opacity: 0;
    --card-title-color: ${({ theme }) => theme.colors.white};
    --card-border-color: ${({ theme }) => theme.colors.gray};

    &:hover {
      --card-image-opacity: 1;
      --card-title-color: ${({ theme }) => theme.colors.brand};
      --card-border-color: ${({ theme }) => theme.colors.brand};
    }
  }
`

export const Media = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: ${mixins.pxToRem(24)};
  transition: 0.3s opacity;

  ${({ theme }) => theme.mediaBreakpointUp('lg')} {
    opacity: var(--card-image-opacity);
    margin-bottom: 0;
    width: 23%;
    height: ${mixins.pxToRem(158)};
    position: absolute;
    left: 0;
    top: 0;
  }
`

export const Picture = styled.div`
  width: 100%;
  padding-top: 66%;
  position: relative;

  div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

export const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
`

export const Title = styled.h3`
  ${({ theme }) =>
    theme.setPropResponsive('font-size', theme.fontSizes.subtitle, true)};
  color: var(--card-title-color);
  font-weight: 400;
  display: none;
  transition: 0.3s;

  ${({ theme }) => theme.mediaBreakpointUp('lg')} {
    display: flex;
    flex: 1 1 60%;
  }
`
export const CardContent = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mediaBreakpointUp('lg')} {
    padding-top: ${mixins.pxToRem(40)};
    padding-bottom: ${mixins.pxToRem(32)};
    flex-direction: row;
    gap: ${mixins.pxToRem(24)};
    /*justify-content: normal;*/
    align-items: normal;
    transition: 0.3s;
    border-top: solid ${mixins.pxToRem(1)} var(--card-border-color);
    width: 74%;
    margin-left: auto;
  }
`
export const CardLink = styled(LinkUnified)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: var(--base-cursor);
  z-index: 10;
`

export const ProjectType = styled.div`
  font-weight: 500;
  align-self: flex-start;
  color: ${(props) => props.theme.colors.white};
  ${({ theme }) =>
    theme.setPropResponsive('font-size', theme.fontSizes.text4, true)}
`
export const HashtagList = styled.ul`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: ${mixins.pxToRem(10)};

  ${({ theme }) => theme.mediaBreakpointUp('lg')} {
    flex: 1 1 auto;
  }
`
