import * as React from 'react'
import { IconStyled } from 'components/UI'

function IconLink({ ...args }) {
  return (
    <IconStyled
      width="26"
      height="26"
      viewBox="0 0 26 26"
      $iconRatio="1:1"
      fill="none"
      {...args}
    >
      <path d="M1.82012 1.84522C1.82012 1.01679 2.4917 0.345217 3.32012 0.345218L24.7374 0.345215C25.1353 0.345216 25.5168 0.503251 25.7981 0.784556C26.0794 1.06586 26.2374 1.44739 26.2374 1.84521L26.2374 23.2625C26.2374 24.091 25.5659 24.7625 24.7374 24.7625C23.909 24.7625 23.2374 24.091 23.2374 23.2625V5.46654L3.48839 25.2156C2.90261 25.8014 1.95286 25.8014 1.36707 25.2156C0.781288 24.6298 0.781288 23.6801 1.36707 23.0943L21.1161 3.34521L3.32012 3.34522C2.4917 3.34522 1.82013 2.67364 1.82012 1.84522Z" />
    </IconStyled>
  )
}

export default IconLink
