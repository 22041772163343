import styled from 'styled-components'
import * as mixins from 'Theme/mixins'
import Wysiwyg from 'components/Wysiwyg'

export const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: ${mixins.pxToRem(54)};
  gap: ${mixins.pxToRem(20)};

  ${({ theme }) => theme.mediaBreakpointUp('md')} {
    flex-direction: row;
    margin-bottom: ${mixins.pxToRem(60)};
    gap: ${mixins.pxToRem(20)};
    align-items: center;
  }
`
export const Summary = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${({ theme }) => theme.mediaBreakpointUp('sm')} {
    flex-direction: row;
  }
`
export const Title = styled.h3`
  ${({ theme }) =>
    theme.setPropResponsive('font-size', theme.fontSizes.title, true)}
`
export const HashtagList = styled.ul`
  display: flex;
  gap: ${mixins.pxToRem(10)};
  flex-wrap: wrap;
`

export const SummaryRequest = styled.div`
  width: 100%;
  border-top: solid ${mixins.pxToRem(1)} ${(props) => props.theme.colors.gray};
  padding-top: ${mixins.pxToRem(32)};
  margin-bottom: ${mixins.pxToRem(60)};

  ${({ theme }) => theme.mediaBreakpointUp('sm')} {
    width: 65%;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp('md')} {
    width: 71%;
  }
`
export const SummaryWysiwyg = styled(Wysiwyg)`
  ${({ theme }) => theme.mediaBreakpointUp('md')} {
    --font-size-text: ${mixins.pxToRem(20)};
    line-height: 1.4;
  }
`

export const SummaryDelivery = styled.div`
  width: 100%;
  border-top: solid ${mixins.pxToRem(1)} ${(props) => props.theme.colors.gray};
  padding-top: ${mixins.pxToRem(32)};

  ${({ theme }) => theme.mediaBreakpointUp('sm')} {
    width: 30%;
  }

  ${({ theme }) => theme.mediaBreakpointUp('md')} {
    width: 23.5%;
  }
`
export const Subtitle = styled.h4`
  ${({ theme }) =>
    theme.setPropResponsive('font-size', theme.fontSizes.subtitle, true)};
  font-weight: 400;
  margin-bottom: ${mixins.pxToRem(17)};
`
export const DeliveryList = styled.ul``
export const DeliveryItem = styled.li`
  font-weight: 400;
  margin-bottom: ${mixins.pxToRem(12)};

  &:last-child {
    margin-bottom: 0;
  }
`
