import styled from 'styled-components'
import * as mixins from 'Theme/mixins'

export const title = styled.h3`
  ${({ theme }) =>
    theme.setPropResponsive('font-size', theme.fontSizes.title, true)};
  text-align: center;
  margin-bottom: ${mixins.pxToRem(40)};
  ${({ theme }) => theme.mediaBreakpointUp('xsm')} {
    text-align: left;
    margin-bottom: ${mixins.pxToRem(60)};
  }
  ${({ theme }) => theme.mediaBreakpointUp('lg')} {
    margin-left: 26%;
  }
`
export const casesList = styled.ul``
