import * as React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useMemo } from 'react'

import ThemeWrapper from 'Theme'
import Layout from 'layout/BaseLayout'
import Seo from 'components/seo'
import * as CustomTypes from 'data/types'
import * as workTypes from 'data/workTypes'

import { ModalProvider } from 'context'
import ContentBlocks, { contentBlockTypes } from 'ContentBlocks'

import ScrollTopButton from 'components/ScrollTopButton'
import DiscoverMore from './DiscoverMore'
import SummaryBlock from './SummaryBlock'
import HeaderBlock from './HeaderBlock'

export const query = graphql`
  query PageWork($pageId: String) {
    wpWork(id: { eq: $pageId }) {
      id
      title
      blocks {
        ...contentBlocksQuery
      }
      ...customWorkSettingsQuery
      ...workTagsQuery

      workPage {
        projectType
        date
        image {
          ...gatsbyImageQuery
        }
        delivered {
          deliveredItem
        }
        summary
        title
        projectLink
      }
      seo {
        ...seoQuery
      }
    }
    wp {
      ...workSettingsPageQuery
      generalSettings {
        title
      }
    }
  }
`

// eslint-disable-next-line react/prop-types
export const Head = ({ data }) => {
  const {
    // eslint-disable-next-line react/prop-types
    wpWork: { title, seo },
  } = data
  return <Seo title={title} seo={seo} />
}

const PageTemplate = ({ data, location }) => {
  const {
    wpWork: {
      id: workId,
      blocks,
      title,
      workPage,
      workTags: { nodes: workTags },
      customWorkSettings,
    },
    wp: {
      workSettingsPage: { singleWorkSettings: contentSetting },
      generalSettings,
    },
  } = data

  const pageConfig = useMemo(() => {
    const cleanedEmptySettings = Object.entries(customWorkSettings).reduce(
      (cleanedSettings, [prop, value]) => {
        if (value) {
          cleanedSettings[prop] = value
        }
        return cleanedSettings
      },
      {}
    )
    return { ...contentSetting, ...cleanedEmptySettings }
  }, [contentSetting, customWorkSettings])
  return (
    <ThemeWrapper>
      <ModalProvider>
        <Layout
          isFooterShort={customWorkSettings.shortFooter}
          generalSettingsTitle={generalSettings.title}
          location={location}
          title={title}
        >
          <HeaderBlock
            date={workPage.date}
            summary={workPage.projectType}
            title={workPage.title}
            image={workPage.image}
            link={workPage.projectLink}
          />
          <SummaryBlock
            deliveryList={workPage.delivered}
            hashtagList={workTags}
            deliveryTitle={pageConfig.deliveredLabel}
            summaryLabel={pageConfig.summaryLabel}
            clientTitle={pageConfig.requestLabel}
            clientDescription={workPage.summary}
          />
          <ContentBlocks blocksData={blocks} />
          <DiscoverMore
            compareProject={{
              workId,
              tags: workTags,
              date: workPage.date,
              type: workPage.projectType,
            }}
            config={pageConfig}
          />
          <ScrollTopButton />
        </Layout>
      </ModalProvider>
    </ThemeWrapper>
  )
}

PageTemplate.propTypes = {
  location: CustomTypes.pageLocationType.isRequired,
  data: PropTypes.shape({
    wpWork: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      customWorkSettings: workTypes.workSettingsType,
      blocks: contentBlockTypes.blocksListType,
      seo: PropTypes.shape({}),
      workPage: PropTypes.shape({
        delivered: PropTypes.arrayOf(
          PropTypes.shape({
            deliveredItem: PropTypes.string,
          })
        ),
        projectType: PropTypes.string,
        date: PropTypes.string,
        fieldGroupName: PropTypes.string,
        title: PropTypes.string,
        summary: PropTypes.string,
        projectLink: PropTypes.string,
        image: CustomTypes.gatsbyImageType,
      }),
      workTags: PropTypes.shape({
        nodes: PropTypes.arrayOf(workTypes.workTagType),
      }),
    }),
    wp: PropTypes.shape({
      workSettingsPage: PropTypes.shape({
        singleWorkSettings: workTypes.workSettingsType,
      }),
      generalSettings: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default PageTemplate
