import styled from 'styled-components'
import * as mixins from 'Theme/mixins'
import LinkUnified from 'components/LinkUnified'

export const container = styled.div`
  width: 100%;
  ${({ theme }) => theme.mediaBreakpointUp('lg')} {
    max-width: 83%;
    margin: 0 auto;
  }
`

export const LinkToProject = styled(LinkUnified)`
  display: flex;
  align-items: center;
  margin-left: ${mixins.pxToRem(18)};
  color: ${(props) => props.theme.colors.brand};
`
export const Inner = styled.div`
  display: flex;
`

export const wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${mixins.pxToRem(16)};
  ${({ theme }) => theme.mediaBreakpointUp('md')} {
    margin-bottom: ${mixins.pxToRem(32)};
  }
`
export const summary = styled.div`
  ${({ theme }) =>
    theme.setPropResponsive('font-size', theme.fontSizes.title, true)}
`

export const Media = styled.div`
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  transform-origin: top center;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mediaBreakpointUp('lg')} {
    display: none;
  }
`

export const MediaAnimate = styled(Media)`
  display: none;
  ${({ theme }) => theme.mediaBreakpointUp('lg')} {
    display: flex;
    width: 100vw;
    ${({ theme }) =>
      theme.setPropResponsive(
        'margin-left',
        Object.keys(theme.spacing.contentX).reduce((newValues, key) => {
          newValues[key] = theme.spacing.contentX[key] * -1
          return newValues
        }, {}),
        true
      )}
  }
`
export const date = styled.div`
  font-weight: 400;
  align-self: end;
  color: ${(props) => props.theme.colors.white};
  ${({ theme }) =>
    theme.setPropResponsive('font-size', theme.fontSizes.text1, true)}
`
export const title = styled.h2`
  margin-bottom: 0;
  ${({ theme }) =>
    theme.setPropResponsive('font-size', theme.fontSizes.titleLarge, true)}
  color: ${(props) => props.theme.colors.brand};
  font-family: ${({ theme }) => theme.fonts.accent};
`
export const buttonBack = styled.button`
  width: 50px;
  height: 50px;
  background: white;
`
